import type { ExperimentConfig } from '../@types'

export default [
	{
		'activity-female-pilates': {
			paywall: {
				experiment: () =>
					import(
						/* webpackChunkName: "plan" */ '@/modules/Paywall/instances/experiments/web_3_upsells_with_no_discount'
					),
			},
		},
		female: {
			paywall: {
				experiment: () =>
					import(
						/* webpackChunkName: "plan" */ '@/modules/Paywall/instances/experiments/web_3_upsells_with_no_discount'
					),
			},
		},
		male: {
			paywall: {
				experiment: () =>
					import(
						/* webpackChunkName: "plan" */ '@/modules/Paywall/instances/experiments/web_3_upsells_with_no_discount'
					),
			},
		},
		'activity-female-yoga': {
			paywall: {
				experiment: () =>
					import(
						/* webpackChunkName: "plan" */ '@/modules/Paywall/instances/experiments/web_3_upsells_with_no_discount'
					),
			},
		},
		'activity-male-yoga': {
			paywall: {
				experiment: () =>
					import(
						/* webpackChunkName: "plan" */ '@/modules/Paywall/instances/experiments/web_3_upsells_with_no_discount'
					),
			},
		},
		'activity-female-walking': {
			paywall: {
				experiment: () =>
					import(
						/* webpackChunkName: "plan" */ '@/modules/Paywall/instances/experiments/web_3_upsells_with_no_discount'
					),
			},
		},
	},
] as ExperimentConfig[]
